var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-pr-sec sticky-titles"},[_c('div',{staticClass:"container pb-2 d-lg-flex align-items-center justify-content-between"},[_c('div',{staticClass:"vcp_inf cr"},[(
          !_vm.$route.path.includes('products') &&
          !_vm.$route.path.includes('category')
        )?_c('div',{staticClass:"vc_info pr"},[_c('h3',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.newProductPath !== '')?_c('router-link',{attrs:{"to":_vm.newProductPath}},[_c('button',{staticClass:"mx-2 btn btn-pill btn-xs subscribe"},[_vm._v(" + "+_vm._s(_vm.$t("addNewProduct"))+" ")])]):_vm._e()],1)]):_vm._e()]),(
        _vm.editUrl !== '' ||
        (_vm.viewAllUrl !== _vm.$route.fullPath &&
          !_vm.$route.fullPath.includes('products'))
      )?_c('ul',{staticClass:"chan_cantrz"},[(
          _vm.viewAllUrl !== _vm.$route.fullPath &&
          !_vm.$route.fullPath.includes('products') &&
          !_vm.$route.fullPath.includes('category')
        )?_c('li',[_c('router-link',{staticClass:"donate",attrs:{"to":_vm.viewAllUrl,"title":""}},[_vm._v(_vm._s(_vm.$t("goToDetail")))])],1):_vm._e(),(_vm.publishButton)?_c('li',[_c('a',{staticClass:"donate",attrs:{"href":"javascript:void(0)","title":""},on:{"click":function($event){return _vm.$emit('publish')}}},[_vm._v(_vm._s(_vm.$t("publish")))])]):_vm._e(),(_vm.editUrl !== '')?_c('li',[_c('a',{staticClass:"subscribe",attrs:{"href":"javascript:void(0)","title":""},on:{"click":function($event){return _vm.$router.replace({ query: { ..._vm.$route.query, ..._vm.editUrl } })}}},[_vm._v(_vm._s(_vm.$t("edit")))])]):_vm._e()]):_vm._e(),(!_vm.noSearch)?_c('div',{staticClass:"search_form"},[_c('form',{on:{"submit":_vm.SET_SUBMIT}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"required":"","type":"text","name":"search","placeholder":_vm.$t('searchIn', { title: _vm.title })},domProps:{"value":(_vm.search)},on:{"keyup":_vm.SET_SUBMIT,"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_vm._m(0)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{attrs:{"type":"submit"}},[_c('i',{staticClass:"icon-search"})])
}]

export { render, staticRenderFns }