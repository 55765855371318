<template>
  <div class="info-pr-sec sticky-titles">
    <div
      class="container pb-2 d-lg-flex align-items-center justify-content-between"
    >
      <div class="vcp_inf cr">
        <div
          class="vc_info pr"
          v-if="
            !$route.path.includes('products') &&
            !$route.path.includes('category')
          "
        >
          <h3 class="d-flex align-items-center">
            {{ title }}
            <router-link v-if="newProductPath !== ''" :to="newProductPath"
              ><button class="mx-2 btn btn-pill btn-xs subscribe">
                + {{ $t("addNewProduct") }}
              </button></router-link
            >
          </h3>
          <!-- <span>437 {{$t("subscribers") }}}</span> -->
        </div>
      </div>
      <!--vcp_inf end-->
      <ul
        v-if="
          editUrl !== '' ||
          (viewAllUrl !== $route.fullPath &&
            !$route.fullPath.includes('products'))
        "
        class="chan_cantrz"
      >
        <li
          v-if="
            viewAllUrl !== $route.fullPath &&
            !$route.fullPath.includes('products') &&
            !$route.fullPath.includes('category')
          "
        >
          <router-link :to="viewAllUrl" title="" class="donate">{{
            $t("goToDetail")
          }}</router-link>
        </li>
        <li v-if="publishButton">
          <a
            href="javascript:void(0)"
            @click="$emit('publish')"
            title=""
            class="donate"
            >{{ $t("publish") }}</a
          >
        </li>
        <li v-if="editUrl !== ''">
          <a
            href="javascript:void(0)"
            @click="$router.replace({ query: { ...$route.query, ...editUrl } })"
            title=""
            class="subscribe"
            >{{ $t("edit") }}</a
          >
        </li>
      </ul>
      <!--chan_cantrz end-->
      <div v-if="!noSearch" class="search_form">
        <form @submit="SET_SUBMIT">
          <input
            required
            v-model="search"
            type="text"
            name="search"
            @keyup="SET_SUBMIT"
            :placeholder="$t('searchIn', { title })"
          />
          <button type="submit">
            <i class="icon-search"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      searchTimeout: -1,
    };
  },
  computed: {
    ...mapGetters(["getSettings"]),
  },
  props: {
    title: {
      default: "",
    },
    // newProductPath url
    newProductPath: {
      default: "",
    },
    // view all url
    viewAllUrl: {
      default: "",
    },
    // edit url
    editUrl: {
      default: "",
    },
    // search
    searchData: {
      default: "",
    },
    noSearch: {
      default: false,
      type: Boolean,
    },
    publishButton: {
      default: false,
      type: Boolean,
    },
    // avatar
    avatar: {
      default: "",
    },
  },
  methods: {
    SET_SUBMIT(e) {
      e.preventDefault();
      if (this.searchTimeout !== -1) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$emit("search:product", this.search);
      }, 1000);
    },
  },
  mounted() {
    this.search = this.searchData;
  },
};
</script>
