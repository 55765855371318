<template>
  <section class="videso_section">
    <Info
      v-if="hasInfo"
      :title="title"
      :view-all-url="viewAllUrl"
      :newProductPath="newProductPath"
      :searchData="search"
      @search:product="(e) => $emit('search:product', e)"
    ></Info>
    <div class="playlist_tab">
      <div class="viddz">
        <div class="container">
          <!--vidz_videos end-->
          <ProductWithTitle
            :title="title"
            :editable="editable"
            :view-all-url="viewAllUrl"
            :productType="productType"
            :productList="productList"
            :newProductPath="newProductPath"
            :limit="limit"
          ></ProductWithTitle>
        </div>
      </div>
      <!--viddz end-->
    </div>
    <!--home_tb_details end-->
  </section>
  <!--Featured Videos end-->
</template>

<script>
import ProductWithTitle from "./ProductWithTitle.vue";
import Info from "./Info.vue";
export default {
  components: { ProductWithTitle, Info },
  props: {
    // filtreleme işleminde kategori id kullanılacaksa category id gönderilir.
    categoryId: {
      default: -1,
    },
    // filtreleme işleminde product type gönderimi zorunludur.
    productType: {
      default: -1,
      required: true,
    },
    // product listesinde kaç adet ürün çekileceğini belirtir.
    limit: {
      default: 12,
    },
    // view all url
    viewAllUrl: {
      default: "",
    },
    hasInfo: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
    },
    newProductPath: {
      default: "",
    },
    editable: {
      default: false,
      type: Boolean,
    },
    productList: {
      default: () => [],
    },
    search: {
      default: "",
    },
  },
  methods: {
    // ürün arama yapar.
    SET_SEARCH_PRODUCT(search) {
      console.log("search", search);
    },
    // async veri için link oluşturulur.
    SET_TO_CREATE_LINK(searchKey = "") {
      const queries = [];
      // category id bulunuyorsa query'e ekle.
      if (this.categoryId !== -1) queries.push(`categoryId=${this.categoryId}`);
      // product type bulunuyorsa query'e ekle.
      if (this.productType !== -1) queries.push(`type=${this.productType}`);
      // search varsa query'e ekle
      if (searchKey !== "") queries.push(`searchKey=${searchKey}`);
      // product listesinde kaç adet ürün çekileceğini belirtir.
      queries.push(`limit=${this.limit}`);
      return `/Products/GetAll?${queries.join("&")}`;
    },
    // product listesi çekilir.
    GET_PRODUCT_LIST(searchKey = "") {
      const url = this.SET_TO_CREATE_LINK(searchKey);
      console.log(url);
    },
  },
  mounted() {
    this.GET_PRODUCT_LIST();
  },
};
</script>
