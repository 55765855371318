export default {
  data() {
    return {
      keyComp: 0,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(data, oldData) {
        if (data.params.type !== oldData.params.type) this.keyComp++;
        if (data.params.id !== oldData.params.id) this.keyComp++;
      },
    },
  },
};
