<template>
  <router-link :to="`/product/${item.type.statePath}-${item.id}`">
    <div
      class="card hover my-1 p-2 m-2 row align-items-center justify-content-between flex-row"
      :class="{ 'offline-card': item.isActive === 0 }"
    >
      <div class="col-12 col-lg-1">
        <img
          @error="(e) => (e.target.src = getSettings.defaultImage)"
          :src="$api.image(item.imagePath)"
          :alt="item.title"
          width="100%"
        />
      </div>
      <div class="col-12 col-xl-11">
        <p>
          <strong>{{ item.title }}</strong>
        </p>
        <p>
          <small>{{ item.category.name }}</small>
        </p>
      </div>
      <div class="col-12 col-lg-2 col-xl-2">
        <span v-if="item.isActive === 0" class="badge btn-pill-xs donate">{{
          $t("offline")
        }}</span>
        <span
          v-else-if="item.isShowcase === 1"
          class="badge btn-pill-xs subscribe"
          >{{ $t("advertisementProduct") }}</span
        >
      </div>
      <div class="col-12 col-lg-3 col-xl-3 mt-2 mt-lg-0">
        <router-link
          :to="`/product/${item.type.statePath}-${item.id}`"
          class="btn btn-pill btn-xs donate btn-block"
          >{{ $t("goToDetail") }} <i class="icon-next"></i
        ></router-link>
      </div>
    </div>
  </router-link>
</template>

<script>
import Levels from "../../plugins/data/Levels.json";
import Languages from "../../plugins/data/Languages.json";
import { convertM2H } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";

export default {
  data() {
    return {
      Levels,
      Languages,
    };
  },
  props: {
    isVideo: {
      default: false,
      type: Boolean,
    },
    item: {
      default: () => {},
    },
  },
  computed: {
    level() {
      const level = Levels.find((level) => level.level === this.item.level);
      if (level) return this.$t(level.Description);
      else return this.$t("notFound");
    },
    language() {
      const language = Languages.find(
        (lang) => lang.Code === this.item.langCode
      );
      if (language) return language.Description;
      else return this.$t("notFound");
    },
    categoryName() {
      const categories = this.$store.state.Category.categories;
      const category = categories.find((c) => c.id === this.item.category.id);
      if (category) return category.name;
      else return this.$t("notFound");
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    convertM2H,
    Currency,
  },
};
</script>
