<template>
  <div class="vidz_list">
    <div
      class="row justify-content-center"
      v-if="productList.length !== 0 || editable"
    >
      <slot v-if="editable" name="editable:product"></slot>
      <div class="col-12 col-xl-6" v-for="(item, i) in productList" :key="i">
        <GridProductCards :item="item" :isVideo="isVideo"></GridProductCards>
        <!--videoo end-->
      </div>
    </div>
    <NoFound
      :buttonText="'+ ' + $t('addNew')"
      :link="link"
      v-else-if="!editable"
    ></NoFound>
  </div>
</template>

<script>
import GridProductCards from "../Cards/GridProductCard.vue";
import NoFound from "../Shared/NoFound.vue";
import Types from "../Type/Types.js";
export default {
  components: { GridProductCards, NoFound },
  mixins: [Types],
  props: {
    // product listesinde kaç adet ürün çekileceğini belirtir.
    limit: {
      default: 12,
    },
    // filtreleme işleminde product type gönderimi zorunludur.
    productType: {
      default: -1,
      required: true,
    },
    isVideo: {
      default: false,
      type: Boolean,
    },
    productList: {
      default: () => [],
    },
    editable: {
      default: false,
      type: Boolean,
    },
    newProductPath: {
      default: "",
    },
  },
  computed: {
    link() {
      return this.newProductPath !== ""
        ? this.newProductPath
        : this.Types.find((t) => t.type === this.productType).newProductPath;
    },
  },
};
</script>
