<template>
  <div class="vidz_sec">
    <Title
      v-if="viewAllUrl !== $route.fullPath"
      :title="title"
      :view-all-url="viewAllUrl"
      :editable="editable"
      :newProductPath="newProductPath"
      :sectionUpdatePath="sectionUpdatePath"
    ></Title>
    <ClearFix></ClearFix>
    <div v-if="loading" class="text-center my-2"><Loading></Loading></div>
    <Products
      v-else
      :productList="productList"
      :isVideo="isVideo"
      :productType="productType"
      :editable="editable"
      :newProductPath="newProductPath"
      :limit="limit"
    >
      <template v-if="editable" v-slot:editable:product>
        <slot name="editable:product"></slot>
      </template>
    </Products>
    <!--vidz_list end-->
  </div>
</template>

<script>
import Title from "./Title.vue";
import ClearFix from "../Shared/ClearFix.vue";
import Products from "./Products.vue";
import Loading from "../Shared/Loading.vue";
export default {
  components: { Title, ClearFix, Products, Loading },
  props: {
    // product listesinde kaç adet ürün çekileceğini belirtir.
    limit: {
      default: 12,
    },
    viewAllUrl: {
      default: "",
    },
    title: {
      default: "",
    },
    loading: {
      default: false,
      type: Boolean,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    // filtreleme işleminde product type gönderimi zorunludur.
    productType: {
      default: -1,
      required: true,
    },
    isVideo: {
      default: false,
      type: Boolean,
    },
    productList: {
      default: () => [],
    },
    newProductPath: {
      default: "",
    },
    sectionUpdatePath: {
      default: () => {
        return {
          modal: "opened",
        };
      },
    },
  },
};
</script>
